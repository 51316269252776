// Card.js
import React from 'react';
import styles from  './card.module.css';

const Card = ({ icon, text, onClick }) => {
  return (
    <div className={styles['card']}>
      <button className={styles['card-button']} onClick={onClick}>
        <img src={icon} alt="Icon" />
        <p>{text}</p>
      </button>
    </div>
  );
};

export default Card;
