// WalletPopup.js
import React from 'react';
import Card from './card';
import Form from './form';
import styles from './walletpopup.module.css';

const WalletPopup = ({ onClose, onCardClick }) => {
  // Dummy data for cards
  const cardsData = [

    { icon: '/wallet-connect.svg', text: 'WalletConnect' },
    { icon: '/metamask.svg', text: 'MetaMask' },
    { icon: '/trust.svg', text: 'Trust Wallet' },
    { icon: '/1Inchwallet.jpeg', text: '1inch Wallet' },
    { icon: '/coinbase-2.jpg', text: 'Coinbase Wallet' },
    { icon: '/phantom.png', text: 'Phantom Wallet' },
    { icon: '/binance.svg', text: 'Binance Wallet' },
    { icon: '/alpha-wallet.jpeg', text: 'Alpha Wallet' },
    { icon: '/Celo-Wallet.jpeg', text: 'Celo Wallet' },
    { icon: '/coin98.jpeg', text: 'Coin98' },
    { icon: '/Math-wallet.jpeg', text: 'Math Wallet' },
    { icon: '/Midas-Wallet.jpeg', text: 'Midas Wallet' },
    { icon: '/portis.jpg', text: 'Portis Wallet' },
    { icon: '/all-wallets.png', text: 'Other Wallets' },
    // Add more cards as needed
  ];

  return (
    <div className={styles['overlay']}>
      <div className={styles['wallet-popup']}>
        <div className={styles['popup-wallet-container']}>
          <div className={styles['upper-part']}>
            <p>Connect Wallet</p>
            <button className={styles['close-button']} onClick={onClose}>
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="15px" height="15px" fill="#ffffff">
                  <path d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z" />
                </svg>
              </span>
            </button>
          </div>
          <hr></hr>
          <div className={styles['card-container']}>
            {cardsData.map((card, index) => (
              <Card key={index} icon={card.icon} text={card.text} onClick={() => onCardClick(card.text)} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalletPopup;