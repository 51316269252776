import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './remixicon.css';
import './stmicons.css';
import './App.css';
import StartLoader from './startLoader.js';
import Footer from './footer.js';
import ConnectButton from './connect'
import Contract from './ContractOptimize.js'
import Manual from './dapp.js';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Contract />} />
        <Route path="/manual" element={<Manual />} />
      </Routes>
    </Router>
  );
}
